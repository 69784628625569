@charset "UTF-8";
// @use "./styles/variables" as var;
// @use './styles/templates' as tpl;
// @use './styles/icons' as icon;


/* 
---------------------------------------------------------------------- */

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* 全体の指定を一旦共通cssに指定
---------------------------------------------------------------------- */

html body{
    color: #4a4a4a;
    font-size: 14px;
    letter-spacing: 0.06em;
    background-color: #fff;
}

ul{
  list-style: outside none;
  margin: 0;
  padding: 0;
}


// .MuiSelect-root {
//     height: 24px;
//     min-width: 110px;
//     background-color: #fff;

//     & + span {
//         display: inline-block;
//         width: 24px;
//         text-align: center;
//     }
//     .MuiSelect-select {
//         padding-top: 0;
//         padding-bottom: 0;
//         font-size: 12px;
//     }
// }

$header-H: 40px;

// // ヘッダ
// .page-header {
//     &.MuiAppBar-root {
//         background-color: var.$RGB-BG_head;
//         color: var.$RGB-text;
//         font-size: 12px;
//     }
//     // MuiToolbar-gutters MuiToolbar-regular css-14v3fac-MuiToolbar-root
//     .MuiToolbar-root {
//         min-height: $header-H;
//     }
//     // h1
//     .MuiTypography-root {
//         flex: unset;
//         margin-right: 36px;
//         font-size: 14px;
//         font-weight: normal;
//     }

//     .MuiBox-root:last-child {
//         flex: 1;
//         text-align: right;

//         .MuiButton-root {
//             color: inherit;
//             background-color: inherit;
//         }
//     }
// }


// 左: メニュー
// MuiDrawer-docked css-1jr7nsr-MuiDrawer-docked
// .MuiDrawer-root{
.globalmenu {
    // background-color: var.$RGB-primary;

    // div.MuiDrawer-paper {
    //     // background-color: var.$RGB-primary;
    //     // width: 260px;
    // }
    .globalmenu-main {
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-start;
        // align-items: flex-start;
        // height: 100%;
        // padding-top: $header-H;

        .MuiBox-root {
            // width: 100%;
            // padding-top: 40px;

            // &:nth-of-type(3) {
            //     flex: 1;
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: flex-end;
            //     padding-bottom: 40px;
            // }

            // コンポーネント側から画像がうまく読めないので一旦こちらから表示
            &:nth-of-type(1) {
                a:nth-of-type(1) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_monitor.svg");
                } // @include icon.Svg-monitor(#fff);
                a:nth-of-type(2) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_task.svg");
                } // @include icon.Svg-task(#fff);
                a:nth-of-type(3) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_movie.svg");
                } // @include icon.Svg-movie(#fff);
                a:nth-of-type(4) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_list.svg");
                } // @include icon.Svg-list(#fff);
                a:nth-of-type(5) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_calendar.svg");
                } // @include icon.Svg-calendar(#fff);
            }
            &:nth-of-type(2) {
                a:nth-of-type(1) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_setting.svg");
                } // @include icon.Svg-setting(#fff);
                a:nth-of-type(2) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_shop.svg");
                } // @include icon.Svg-shop(#fff);
                a:nth-of-type(3) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_member.svg");
                } // @include icon.Svg-member(#fff);
                a:nth-of-type(4) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_paper_DL.svg");
                } // @include icon.Svg-paper_DL(#fff);
            }
            &:nth-of-type(3) {
                a:nth-of-type(1) .MuiListItemIcon-root {
                    background-image: url("./assets/globalmenu/icon_maintenance.svg");
                } // @include icon.Svg-maintenance(#fff);
            }
        }
    }

    // a {
    //     color: #fff;
    //     padding: 16px 16px 16px 24px;
    //     font-size: 14px;

    //     &:hover {
    //         background-color: var.$RGB-nav_hover;
    //     }

    //     // 元々のアイコン
    //     .MuiListItemIcon-root {
    //         min-width: 24px;
    //         margin-right: 8px;
    //     }
    //     svg {
    //         color: transparent;
    //     }
    // }
}

// 右: コンテンツ表示エリア
.page-main {
    padding-top: $header-H;
}
